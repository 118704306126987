<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-popup title="Gamme de verre" :active.sync="popupGammeVerre">
          <div class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-select label="Type de verre"  autocomplete v-model="IdFoyer">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input type="text" label="Libelle Gamme"  name="Libelle_gamme" :value="Libelle_GamVer" @change.native="Libelle_GamVer=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-button class="ml-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddGammeDeVerre()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table stripe noDataText="Aucune donnée" pagination max-items="10" search :data="gammeVerresData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="Libelle_GamVer">
                    Libelle gamme
                  </vs-th>
                  <vs-th>
                    type verre
                  </vs-th>
                  <vs-th>
                    Fournisseur
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.Libelle_GamVer">
                      {{tr.Libelle_GamVer}}
                      <template slot="edit">
                        <vs-input :value="tr.Libelle_GamVer" @change.native="tr.Libelle_GamVer=$event.target.value" class="inputx" placeholder="Libelle Gamme" @blur="UpdateGammeVerre(tr.Libelle_GamVer, tr.IdFoyer, tr._id, tr.IdFourVerr)" />
                      </template>
                    </vs-td>
                    <vs-td :data="tr.IdFoyer">
                      {{showFoyerName(tr.IdFoyer) ? showFoyerName(tr.IdFoyer) : ''}}
                      <template slot="edit">
                        <vs-select placeholder="Type de verre"  autocomplete v-model="tr.IdFoyer">
                          <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                        </vs-select>

                        <vs-button class="ml-2" @click.stop="UpdateGammeVerre(tr.Libelle_GamVer, tr.IdFoyer, tr._id, tr.IdFourVerr)">MODIFIER</vs-button>
                      </template>
                    </vs-td>
                    <vs-td :data="tr.IdFourVerr">
                      {{getFournisseurVerreById(tr.IdFourVerr) ? getFournisseurVerreById(tr.IdFourVerr).Fournisseur : ''}}
                      <template slot="edit">
                        <vs-select placeholder="Fournisseur"  autocomplete v-model="tr.IdFourVerr">
                          <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                        </vs-select>

                        <vs-button class="ml-2" @click.stop="UpdateGammeVerre(tr.Libelle_GamVer, tr.IdFoyer, tr._id, tr.IdFourVerr)">MODIFIER</vs-button>
                      </template>
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteGammeVerre(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup fullscreen title="Fabrication" :active.sync="popupFabrication">
          <div class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="text" label="Libelle Fabrication"  name="Libelle_Fabrication" :value="Libelle_Fabrication" @change.native="Libelle_Fabrication=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix achât"  name="Prix_achat_Fabrication" :value="Prix_achat_Fabrication" @change.native="Prix_achat_Fabrication=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix vente"  name="Prix_vente_Fabrication" :value="Prix_vente_Fabrication" @change.native="Prix_vente_Fabrication=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-button class="ml-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddFabrication()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table
                stripe
                noDataText="Aucune donnée"
                pagination max-items="20"
                search :data="fabricationData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th>
                    Fournisseur
                  </vs-th>
                  <vs-th sort-key="Libelle_Fabrication">
                    Libelle Fabrication
                  </vs-th>
                  <vs-th>
                    Prix achât
                  </vs-th>
                  <vs-th>
                    Prix vente
                  </vs-th>
                  <vs-th>
                    Marge
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.IdFour">
                      <template v-if="tr.IdFour">
                        <template>
                        {{getFournisseurVerreById(tr.IdFour) ? getFournisseurVerreById(tr.IdFour).Fournisseur : ''}}
                        <template slot="edit">
                          <vs-select class="md:w-1/6" label="Fournisseur"  autocomplete v-model="tr.IdFour">
                            <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                          </vs-select>
                          <vs-input class="ml-2 md:w-1/6" type="text" label="Libelle Fabrication"  name="Libelle_Fabrication" :value="tr.Libelle_Fabrication" @change.native="tr.Libelle_Fabrication=$event.target.value"/>
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix achât"  name="Prix_achat_Fabrication" :value="tr.Prix_achat_Fabrication" @change.native="tr.Prix_achat_Fabrication=$event.target.value" />
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix vente"  name="Prix_vente_Fabrication" :value="tr.Prix_vente_Fabrication" @change.native="tr.Prix_vente_Fabrication=$event.target.value" />
                          <vs-button class="ml-2 mt-5" @click.stop="updateFabrication(tr.Libelle_Fabrication, tr.Prix_achat_Fabrication, tr.Prix_vente_Fabrication, tr._id, tr.IdFour)">MODIFIER</vs-button>
                        </template>
                      </template>
                      </template>
                    </vs-td>

                    <vs-td :data="tr.Libelle_Fabrication">
                      {{tr.Libelle_Fabrication}}
                    </vs-td>

                    <vs-td :data="tr.Prix_achat_Fabrication">
                      {{tr.Prix_achat_Fabrication}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Fabrication">
                      {{tr.Prix_vente_Fabrication}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Fabrication">
                      {{(tr.Prix_vente_Fabrication ? tr.Prix_vente_Fabrication : 0) - (tr.Prix_achat_Fabrication ? tr.Prix_achat_Fabrication : 0)}}
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteFabrication(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup fullscreen title="Traitement" :active.sync="popupTraitement">
          <div class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-select label="Matière" class="ml-2"  autocomplete v-model="Id_Matiere">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="text" label="Libelle Traitement"  name="Libelle_Traitement" :value="Libelle_Traitement" @change.native="Libelle_Traitement=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix achât"  name="Prix_achat_Traitement" :value="Prix_achat_Traitement" @change.native="Prix_achat_Traitement=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix vente"  name="Prix_vente_Traitement" :value="prix_Traitement" @change.native="prix_Traitement=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-button class="ml-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddTraitement()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table
                stripe
                noDataText="Aucune donnée"
                pagination max-items="20"
                search :data="traitementsData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th>
                    Fournisseur
                  </vs-th>
                  <vs-th>
                    Matiere
                  </vs-th>
                  <vs-th sort-key="Libelle_Traitement">
                    Libelle Traitement
                  </vs-th>
                  <vs-th>
                    Prix achât
                  </vs-th>
                  <vs-th>
                    Prix vente
                  </vs-th>
                  <vs-th>
                    Marge
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.IdFour">
                      <template>
                        {{getFournisseurVerreById(tr.IdFour) ? getFournisseurVerreById(tr.IdFour).Fournisseur : ''}}
                        <template slot="edit">
                          <vs-select class="md:w-1/6" label="Fournisseur"  autocomplete v-model="tr.IdFour">
                            <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                          </vs-select>
                          <vs-select label="Matière" class="ml-2 md:w-1/6"  autocomplete v-model="tr.IdMatiere">
                            <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                          </vs-select>
                          <vs-input class="ml-2 md:w-1/6" type="text" label="Libelle Traitement"  name="Libelle_Traitement" :value="tr.Libelle_Traitement" @change.native="tr.Libelle_Traitement=$event.target.value"/>
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix achât"  name="Prix_achat_Traitement" :value="tr.prix_achat" @change.native="tr.prix_achat=$event.target.value" />
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix vente"  name="Prix_Traitement" :value="tr.prix_Traitement" @change.native="tr.prix_Traitement=$event.target.value" />
                          <vs-button class="ml-2 mt-5" @click.stop="updateTraitement(tr.Libelle_Traitement, tr.prix_achat, tr.prix_Traitement, tr._id, tr.IdFour, tr.IdMatiere)">MODIFIER</vs-button>
                        </template>
                      </template>
                    </vs-td>

                    <vs-td>
                      <template v-if="tr.IdMatiere">
                        {{showMatiereName(tr.IdMatiere)}}
                      </template>
                    </vs-td>

                    <vs-td :data="tr.Libelle_Traitement">
                      {{tr.Libelle_Traitement}}
                    </vs-td>

                    <vs-td :data="tr.prix_achat">
                      {{tr.prix_achat}}
                    </vs-td>

                    <vs-td :data="tr.prix_Traitement">
                      {{tr.prix_Traitement}}
                    </vs-td>

                    <vs-td :data="tr.prix_Traitement">
                      {{(tr.prix_Traitement ? tr.prix_Traitement : 0) - (tr.prix_achat ? tr.prix_achat : 0)}}
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteTraitement(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup fullscreen title="Coloration" :active.sync="popupColoration">
          <div class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="text" label="Libelle Coloration"  name="Libelle_Coloration" :value="Libelle_Coloration" @change.native="Libelle_Coloration=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix achât"  name="Prix_achat_Coloration" :value="Prix_achat_Coloration" @change.native="Prix_achat_Coloration=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix vente"  name="Prix_vente_Coloration" :value="Prix_vente_Coloration" @change.native="Prix_vente_Coloration=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-button class="ml-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddColoration()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table
                stripe
                noDataText="Aucune donnée"
                pagination max-items="20"
                search :data="colorationData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th>
                    Fournisseur
                  </vs-th>
                  <vs-th sort-key="Libelle_Coloration">
                    Libelle Coloration
                  </vs-th>
                  <vs-th>
                    Prix achât
                  </vs-th>
                  <vs-th>
                    Prix vente
                  </vs-th>
                  <vs-th>
                    Marge
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.IdFour">
                      <template>
                        {{getFournisseurVerreById(tr.IdFour) ? getFournisseurVerreById(tr.IdFour).Fournisseur : ''}}
                        <template slot="edit">
                          <vs-select class="md:w-1/6" label="Fournisseur"  autocomplete v-model="tr.IdFour">
                            <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                          </vs-select>
                          <vs-input class="ml-2 md:w-1/6" type="text" label="Libelle coloration"  name="Libelle_Coloration" :value="tr.Libelle_Coloration" @change.native="tr.Libelle_Coloration=$event.target.value"/>
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix achât"  name="Prix_achat_Traitement" :value="tr.Prix_achat_Coloration" @change.native="tr.Prix_achat_Coloration=$event.target.value" />
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix vente"  name="Prix_Traitement" :value="tr.Prix_vente_Coloration" @change.native="tr.Prix_vente_Coloration=$event.target.value" />
                          <vs-button class="ml-2 mt-5" @click.stop="updateColoration(tr.Libelle_Coloration, tr.Prix_achat_Coloration, tr.Prix_vente_Coloration, tr._id, tr.IdFour)">MODIFIER</vs-button>
                        </template>
                      </template>
                    </vs-td>

                    <vs-td :data="tr.Libelle_Coloration">
                      {{tr.Libelle_Coloration}}
                    </vs-td>

                    <vs-td :data="tr.Prix_achat_Coloration">
                      {{tr.Prix_achat_Coloration}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Coloration">
                      {{tr.Prix_vente_Coloration}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Coloration">
                      {{(tr.Prix_vente_Coloration ? tr.Prix_vente_Coloration : 0) - (tr.Prix_achat_Coloration ? tr.Prix_achat_Coloration : 0)}}
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteColoration(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup fullscreen title="Teinte" :active.sync="popupTeinte">
          <div class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="text" label="Libelle Teinte"  name="Libelle_Teinte" :value="Libelle_Teinte" @change.native="Libelle_Teinte=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix achât"  name="Prix_achat_Teinte" :value="Prix_achat_Teinte" @change.native="Prix_achat_Teinte=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="ml-2" type="number" label="Prix vente"  name="Prix_vente_Teinte" :value="Prix_vente_Teinte" @change.native="Prix_vente_Teinte=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-button class="ml-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddTeinte()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table
                stripe
                noDataText="Aucune donnée"
                pagination max-items="20"
                search :data="teinteData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th>
                    Fournisseur
                  </vs-th>
                  <vs-th sort-key="Libelle_Teinte">
                    Libelle
                  </vs-th>
                  <vs-th>
                    Prix achât
                  </vs-th>
                  <vs-th>
                    Prix vente
                  </vs-th>
                  <vs-th>
                    Marge
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.IdFour">
                      <template>
                        {{getFournisseurVerreById(tr.IdFour) ? getFournisseurVerreById(tr.IdFour).Fournisseur : ''}}
                        <template slot="edit">
                          <vs-select class="md:w-1/6" label="Fournisseur"  autocomplete v-model="tr.IdFour">
                            <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                          </vs-select>
                          <vs-input class="ml-2 md:w-1/6" type="text" label="Libelle teinte"  name="Libelle_Teinte" :value="tr.Libelle_Teinte" @change.native="tr.Libelle_Teinte=$event.target.value"/>
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix achât"  name="Prix_achat_Teinte" :value="tr.Prix_achat_Teinte" @change.native="tr.Prix_achat_Teinte=$event.target.value" />
                          <vs-input class="ml-2 md:w-1/6" type="number" label="Prix vente"  name="Prix_vente_Teinte" :value="tr.Prix_vente_Teinte" @change.native="tr.Prix_vente_Teinte=$event.target.value" />
                          <vs-button class="ml-2 mt-5" @click.stop="updateTeinte(tr.Libelle_Teinte, tr.Prix_achat_Teinte, tr.Prix_vente_Teinte, tr._id, tr.IdFour)">MODIFIER</vs-button>
                        </template>
                      </template>
                    </vs-td>

                    <vs-td :data="tr.Libelle_Teinte">
                      {{tr.Libelle_Teinte}}
                    </vs-td>

                    <vs-td :data="tr.Prix_achat_Teinte">
                      {{tr.Prix_achat_Teinte}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Teinte">
                      {{tr.Prix_vente_Teinte}}
                    </vs-td>

                    <vs-td :data="tr.Prix_vente_Teinte">
                      {{(tr.Prix_vente_Teinte ? tr.Prix_vente_Teinte : 0) - (tr.Prix_achat_Teinte ? tr.Prix_achat_Teinte : 0)}}
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteTeinte(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup title="Type de verre (Foyer)" :active.sync="popupFoyerVerre">
          <div  class="vx-row">
            <div class="vx-col w-full">

              <vs-table stripe noDataText="Aucune donnée" pagination max-items="10" search :data="typeVerreData">
                <template slot="header">
                  <vs-input class="" type="text" placeholder="Libelle Type"  name="Libelle_Foyer" :value="Libelle_Foyer" @change.native="Libelle_Foyer=$event.target.value"/>
                  <vs-button class="ml-2" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddTypeDeVerre()"></vs-button>
                </template>
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="Libelle_GamVer">
                    Libelle Type
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.Libelle_Foyer">
                      {{tr.Libelle_Foyer}}
                      <template slot="edit">
                        <vs-input :value="tr.Libelle_Foyer" @change.native="tr.Libelle_Foyer=$event.target.value" class="inputx" placeholder="Libelle type" @blur="UpdateTypeVerre(tr.Libelle_Foyer, tr._id)" />
                      </template>
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteTypeVerre(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup title="Matière" :active.sync="popupMatiereVerre">
          <div  class="vx-row">
            <div class="vx-col w-full">
              <vs-table stripe noDataText="Aucune donnée" pagination max-items="10" search :data="matiereVerreData">
                <template slot="header">
                  <vs-input class="" type="text" placeholder="Libelle Matière"  name="Libelle_Matiere" :value="Libelle_Matiere" @change.native="Libelle_Matiere=$event.target.value"/>
                  <vs-button class="ml-2" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddMatiereVerre()"></vs-button>
                </template>
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="Libelle_GamVer">
                    Libelle Matière
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.Libelle_Matiere">
                      {{tr.Libelle_Matiere}}
                      <template slot="edit">
                        <vs-input :value="tr.Libelle_Matiere" @change.native="tr.Libelle_Matiere=$event.target.value" class="inputx" placeholder="Libelle matière" @blur="UpdateMatiereVerre(tr.Libelle_Matiere, tr._id)" />
                      </template>
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteMatiereVerre(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup title="Indice" :active.sync="popupIndiceVerre">
          <div  class="vx-row">
            <div class="vx-col w-full">

              <vs-table stipe noDataText="Aucune donnée" pagination max-items="10" search :data="indiceVerreData">
                <template slot="header">
                  <vs-input class="" type="number" placeholder="Indice"  name="Indice" :value="indice" @change.native="indice=$event.target.value"/>
                  <vs-button class="ml-2" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddIndiceVerre()"></vs-button>
                </template>
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="indice">
                    Indice
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>

                    <vs-td :data="tr.indice">
                      {{tr.indice}}
                      <template slot="edit">
                        <vs-input :value="tr.indice" @change.native="tr.indice=$event.target.value" type="number" class="inputx" placeholder="Indice" @blur="updateIndiceVerre(tr.indice, tr._id)" />
                      </template>
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteIndiceVerre(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vs-popup fullscreen title="Option" :active.sync="popupVersionVerre">
          <div  class="vx-row">
            <div class="vx-col w-full bg-grey-light pt-5">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-select class="mr-2" label="Fournisseur"  autocomplete v-model="IdFourVerr">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-select class="mr-2" label="Gamme de verre"  autocomplete v-model="IdGam">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Libelle Option"  name="Libelle_version" :value="Libelle_version" @change.native="Libelle_version=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-select label="Indice" class="mr-2"  autocomplete v-model="Id_Indice">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Indice (nom four.)"  name="Libelle_indice_Four" :value="Libelle_indice_Four" @change.native="Libelle_indice_Four=$event.target.value"/>
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="number" label="Prix achât"  name="Prix_achat_version" :value="Prix_achat_version" @change.native="Prix_achat_version=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="number" label="Prix vente"  name="Prix_vente_version" :value="Prix_vente_version" @change.native="Prix_vente_version=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="number" label="Majoration du prix de base"  name="Majore_Prix_Base" :value="Majore_Prix_Base" @change.native="Majore_Prix_Base=$event.target.value" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Sph Inf."  name="Sph_Inf" :value="Sph_Inf" @change.native="FixSphInfValue($event.target.value)" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Sph Sup."  name="Sph_Sup" :value="Sph_Sup" @change.native="FixSphSupValue($event.target.value)" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Cyl Inf."  name="Cyl_Inf" :value="Cyl_Inf" @change.native="FixCylInfValue($event.target.value)" />
                </div>
                <div class="vx-col">
                  <vs-input class="mr-2" type="text" label="Cyl Sup."  name="Cyl_Sup" :value="Cyl_Sup" @change.native="FixCylSupValue($event.target.value)" />
                </div>
                <div class="vx-col">
                  <vs-checkbox class="mt-8" v-model="Prix_Base">Prix de base</vs-checkbox>
                </div>
                <div class="vx-col text-right">
                  <vs-button class="mr-2 mt-5" icon-pack="feather" icon="icon-plus-circle" @click.stop="AddVersionVerre()">Ajouter</vs-button>
                </div>
              </div>
              <vs-divider/>
            </div>
            <div class="vx-col w-full">

              <vs-table stripe noDataText="Aucune donnée" pagination max-items="20" search :data="versionVerreData">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="IdFour">
                    Fourn.
                  </vs-th>
                  <vs-th sort-key="IdGam">
                    Gamme
                  </vs-th>
                  <vs-th sort-key="Libelle_version">
                    Libelle
                  </vs-th>
                  <vs-th>
                    Indice
                  </vs-th>
                  <vs-th>
                    Indice Four.
                  </vs-th>
                  <vs-th sort-key="Prix_achat_version">
                    Prix achât
                  </vs-th>
                  <vs-th sort-key="Prix_vente_version">
                    prix vente
                  </vs-th>
                  <vs-th>
                    prix base
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>
                    <vs-td :data="tr.IdFour">
                      {{getFournisseurVerreById(tr.IdFour) ? getFournisseurVerreById(tr.IdFour).Fournisseur : ''}}
                    </vs-td>
                    <vs-td :data="tr.IdGam">
                      {{showGammeName(tr.IdGam) ? showGammeName(tr.IdGam) : ''}}
                    </vs-td>
                    <vs-td :data="tr.Libelle_version">
                      {{tr.Libelle_version}}
                      <template slot="edit">
                        <div class="vx-row">
                          <div class="vx-col w-1/4">
                            <vs-select class="md:w-full" label="Fournisseur"  autocomplete v-model="tr.IdFour">
                              <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                            </vs-select>
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-select label="Gamme" class="md:w-full"  autocomplete v-model="tr.IdGam">
                              <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                            </vs-select>
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input :value="tr.Libelle_version" @change.native="tr.Libelle_version=$event.target.value" class="md:w-full" label="Libelle Option" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-select label="Indice" class="md:w-full"  autocomplete v-model="tr.IdIndice">
                              <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                            </vs-select>
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="text" label="Indice (nom four.)"  name="Libelle_indice_Four" :value="tr.Libelle_indice_Four" @change.native="tr.Libelle_indice_Four=$event.target.value"/>
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Prix achât"  name="Prix_achat_version" :value="tr.Prix_achat_version" @change.native="tr.Prix_achat_version=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Prix vente"  name="Prix_vente_version" :value="tr.Prix_vente_version" @change.native="tr.Prix_vente_version=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Majoration du prix de base"  name="Majore_Prix_Base" :value="tr.Majore_Prix_Base" @change.native="tr.Majore_Prix_Base=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Sph Inf."  name="Sph_Inf" :value="tr.Sph_Inf" @change.native="tr.Sph_Inf=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Sph Sup."  name="Sph_Sup" :value="tr.Sph_Sup" @change.native="tr.Sph_Sup=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Cyl Inf."  name="Cyl_Inf" :value="tr.Cyl_Inf" @change.native="tr.Cyl_Inf=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-input class="mr-2 w-full" type="number" label="Cyl Sup."  name="Cyl_Sup" :value="tr.Cyl_Sup" @change.native="tr.Cyl_Sup=$event.target.value" />
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-checkbox class="mt-8" v-model="tr.Prix_Base">Prix de base</vs-checkbox>
                          </div>
                          <div class="vx-col w-1/4">
                            <vs-button class="mr-2 mt-5" @click.stop="updateVersionVerre(tr.IdFour,tr.IdGam,tr.IdIndice,tr.Libelle_indice_Four, tr.Libelle_version, tr.Prix_achat_version, tr.Prix_vente_version, tr.Majore_Prix_Base,tr.Prix_Base, tr.Sph_Inf, tr.Sph_Sup, tr.Cyl_Inf, tr.Cyl_Sup, tr._id)">MODIFIER</vs-button>
                          </div>
                        </div>
                      </template>
                    </vs-td>
                    <vs-td :data="tr.IdIndice">
                      {{showIndiceName(tr.IdIndice) ? showIndiceName(tr.IdIndice) : ''}}
                    </vs-td>
                    <vs-td :data="tr.Libelle_indice_Four">
                      {{tr.Libelle_indice_Four}}
                    </vs-td>
                    <vs-td :data="tr.Prix_achat_version">
                      {{tr.Prix_achat_version}}
                    </vs-td>
                    <vs-td v-if="tr.Prix_Base === true" :data="tr.Prix_vente_version">
                      {{tr.Prix_vente_version}}
                    </vs-td>
                    <vs-td v-else>
                      {{calculPrixVenteOption(tr.IdGam, tr.IdIndice, tr.Majore_Prix_Base)}}
                    </vs-td>
                    <vs-td>
                      <template v-if="tr.Prix_Base == true">
                        <vs-chip transparent color="success">
                          oui
                        </vs-chip>

                      </template>
                      <template v-else>
                        <vs-chip transparent color="danger">
                          non
                        </vs-chip>
                      </template>
                    </vs-td>

                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteVersionVerre(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-popup>
        <vx-card>
          <div class="vx-row">
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black" @click.stop="showGammeVerre()">Gamme de verre</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="popupTraitement=true">Traitement</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black" @click.stop="showTypeVerre()" >Type de verre</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="popupTeinte=true">Teinte</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="popupColoration=true">Coloration</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="showMatiereVerre()">Matière</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="showIndiceVerre()">Indice</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="showVersionVerre()">Option</vs-button>
            </div>
            <div class="vx-col md:w-1/7 mt-1">
              <vs-button color="lightgray" class="w-full text-black"  @click.stop="popupFabrication=true">Fabrication</vs-button>
            </div>
          </div>
          <div class="vx-row">
          </div>
        </vx-card>
        <vx-card title="VERRES" class="min-h-screen mt-5">
          <div class="vx-row">
            <div class="vx-col md:w-1/3">
              <vs-select class="w-full" label="Fournisseur"  autocomplete v-model="IdFourVerrFilter" @change="showCatalogueInfo(IdFourVerrFilter)">
                <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/3">
              <vs-select label="Gamme de verre" class="w-full"  autocomplete v-model="IdGammeVerreFilter" @change="showOptionInfo(IdGammeVerreFilter)">
                <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
              </vs-select>
            </div>
          </div>
          <div class="vx-row">
            <div v-if="current_Options" class="vx-col w-full mt-5">
              <table class="w-full Table" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="thTable bg-primary text-white" style="border-bottom-width: 0px;border-left-width: 0px;"></th>
                      <th class="thTable bg-primary text-white" style="border-bottom-width: 0px;border-left-width: 0px;" :key="index" v-for="(tr, index) in groupByProperty(current_Options, 'Libelle_version')">{{tr.length > 0 ? tr[0].Libelle_version : ""}}</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template>
                    <tr :key="index" v-for="(tr, index) in groupByProperty(current_Options, 'Libelle_indice_Four')">
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;">
                        {{tr.length > 0 ? tr[0].Libelle_indice_Four : ""}}
                      </td>
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;" :key="index2" v-for="(tr2, index2) in groupByProperty(current_Options, 'Libelle_version')">
                        {{getGammeMatricePrice(tr2,tr[0].IdIndice) ? (getGammeMatricePrice(tr2,tr[0].IdIndice).Prix_Base ? getGammeMatricePrice(tr2,tr[0].IdIndice).Prix_vente_version : calculPrixVenteOption(getGammeMatricePrice(tr2,tr[0].IdIndice).IdGam, getGammeMatricePrice(tr2,tr[0].IdIndice).IdIndice, getGammeMatricePrice(tr2,tr[0].IdIndice).Majore_Prix_Base)) : "-"}}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="current_Fabrications" class="vx-col w-1/3 mt-5">
              <table class="w-full Table" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="thTable text-center bg-primary text-white" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="12">Fabrication</th>
                    </tr>
                    <tr>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;"  colspan="6">Libelle</th>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="6">Prix</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template>
                    <tr :key="index" v-for="(tr, index) in current_Fabrications">
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;" colspan="6">
                        {{tr.Libelle_Fabrication}}
                      </td>
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="6">
                        {{tr.Prix_vente_Fabrication}}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="current_Traitements" class="vx-col w-1/3 mt-5">
              <table class="w-full Table" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="thTable text-center bg-primary text-white" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="12">Traitements</th>
                    </tr>
                    <tr>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;"  colspan="6">Libelle</th>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="6">Prix</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template>
                    <tr :key="index" v-for="(tr, index) in current_Traitements">
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;" colspan="6">
                        {{tr.Libelle_Traitement}}
                      </td>
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="6">
                        {{tr.prix_Traitement}}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="current_Colorations" class="vx-col w-1/3 mt-5">
              <table class="w-full Table" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="thTable text-center bg-primary text-white" style="border-top-width: 0px;border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="12">Colorations sur verre blanc</th>
                    </tr>
                    <tr>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;"  colspan="6">Libelle</th>
                      <th class="thTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;"  colspan="6">Prix</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template>
                    <tr :key="index" v-for="(tr, index) in current_Colorations">
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;" colspan="6">
                        {{tr.Libelle_Coloration}}
                      </td>
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="6">
                        {{tr.Prix_vente_Coloration}}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="current_Options" class="vx-col w-full mt-5">
              <table class="w-full Table" cellpadding="0" cellspacing="0">
                <thead>
                  <template>
                    <tr>
                      <th class="thTable bg-primary text-white" style="border-bottom-width: 0px;border-left-width: 0px;">Plage de puissance</th>
                      <th class="thTable bg-primary text-white" style="border-bottom-width: 0px;border-left-width: 0px;" :key="index" v-for="(tr, index) in groupByProperty(current_Options, 'Libelle_version')">{{tr.length > 0 ? tr[0].Libelle_version : ""}}</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template>
                    <tr :key="index" v-for="(tr, index) in groupByProperty(current_Options, 'Libelle_indice_Four')">
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;">
                        {{tr.length > 0 ? tr[0].Libelle_indice_Four : ""}}
                      </td>
                      <td class="tdTable" style="border-bottom-width: 0px;border-left-width: 0px;" :key="index2" v-for="(tr2, index2) in groupByProperty(current_Options, 'Libelle_version')">
                        <div> De SphInf: {{getGammeMatricePrice(tr2,tr[0].IdIndice) ? FixSphInfValueUpdate(getGammeMatricePrice(tr2,tr[0].IdIndice).Sph_Inf) : "-"}} à SphSup: {{getGammeMatricePrice(tr2,tr[0].IdIndice) ? FixSphSupValueUpdate(getGammeMatricePrice(tr2,tr[0].IdIndice).Sph_Sup) : "-"}} </div>
                        <div> De CylInf: {{getGammeMatricePrice(tr2,tr[0].IdIndice) ? FixCylInfValueUpdate(getGammeMatricePrice(tr2,tr[0].IdIndice).Cyl_Inf) : "-"}} à CylSup: {{getGammeMatricePrice(tr2,tr[0].IdIndice) ? FixCylSupValueUpdate(getGammeMatricePrice(tr2,tr[0].IdIndice).Cyl_Sup) : "-"}} </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div>

          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

      /* fourniseeur verre */
      IdFourVerr: null,


      popupGammeVerre: false,
      popupReferenceVerre: false,
      popupFoyerVerre: false,
      popupIndiceVerre: false,
      popupMatiereVerre: false,
      popupVersionVerre: false,
      popupUpdateModele: false,
      popupTraitement: false,
      popupFabrication: false,
      popupColoration: false,
      popupTeinte: false,


      Type_verre: '',
      Surface: '',
      Matiere: '',
      Matieres: [],

      /* Gamme de verre */
      Libelle_GamVer: '',
      IdGammeVerre: null,
      selectedGammeVerres: [],

      /* Foyer verre (type de verre) */
      Libelle_Foyer: null,
      IdFoyer: null,

      /* Matière verre */
      Libelle_Matiere: '',
      IdMatiere: null,

      /* Indice verre */
      indice: null,
      IdIndice: null,

      /* Version verre */
      Version: '',
      Libelle_version: '',
      Libelle_indice_Four: '',
      Prix_achat_version: 0,
      Prix_vente_version: 0,
      Majore_Prix_Base: 0,
      Prix_Base: false,
      IdVersion: null,
      Sph_Inf: null,
      Sph_Sup: null,
      Cyl_Inf: null,
      Cyl_Sup: null,

      /* modele verre */
      IdGam: null,
      Id_Version: null,
      Id_Matiere: null,
      Id_Foyer: null,
      Id_Indice: null,
      PuissanceInf: 0,
      PuissanceSup: 0,
      Libelle_Model: '',
      Diametre: '',

      /* update modele verre */
      IdGam_update: null,
      Libelle_Model_update: null,
      IdFourVerr_update: null,
      Id_Foyer_update: null,
      Id_Indice_update: null,
      Id_Matiere_update: null,
      PuissanceInf_update: null,
      PuissanceSup_update: null,
      Id_Version_update: null,
      Diametre_update: null,
      popupUpdateModele_update: null,

      /* Matrice de prix */
      IdModelVer: null,
      SphInf: '',
      SphSup: '',
      CylInf: '',
      CylSup: '',
      Prix_Verre_Matrix: '',

      /* show on add matrice price */
      show_version_name: '',
      show_gamme_verre_name: '',
      show_matiere_verre_name: '',
      show_foyer_verre_name: '',
      show_indice_verre_name: '',
      lignes: [],

      /* Traitement */
      IdTraitement: null,
      Libelle_Traitement: '',
      prix_Traitement: 0,
      Prix_achat_Traitement: 0,

      /* Coloration */
      IdColoration: null,
      Libelle_Coloration: '',
      Prix_achat_Coloration: 0,
      Prix_vente_Coloration: 0,

      /* Fabrication */
      IdFabrication: null,
      Libelle_Fabrication: '',
      Prix_achat_Fabrication: 0,
      Prix_vente_Fabrication: 0,

      /* Teinte */
      IdTeinte: null,
      Libelle_Teinte: '',
      Prix_achat_Teinte: 0,
      Prix_vente_Teinte: 0,

      current_Fabrications: [],
      current_Traitements: [],
      current_Colorations: [],
      current_Gammes: [],
      current_Options: [],
      current_Plages: [],
      IdFourVerrFilter: null,
      IdGammeVerreFilter: null
    }
  },
  computed: {
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    gammeVerresData () {
      return this.$store.state.verre.gammes_verres
    },
    traitementsData () {
      return this.$store.state.verre.traitements
    },
    colorationData () {
      return this.$store.state.verre.colorations
    },
    typeVerreData () {
      return this.$store.state.verre.types_verre
    },
    matiereVerreData () {
      return this.$store.state.verre.matieres_verres
    },
    indiceVerreData () {
      return this.$store.state.verre.indices
    },
    versionVerreData () {
      return this.$store.state.verre.versions
    },
    modeleVerreData () {
      return this.$store.state.verre.modeles_verre
    },
    fabricationData () {
      return this.$store.state.verre.fabrications
    },
    teinteData () {
      return this.$store.state.verre.teintes
    }
  },
  methods: {
    FixSphInfValue (sph) {
      //convert string to float and fix
      const Sph_Inf = sph ? parseFloat(sph).toFixed(2) : null

      this.Sph_Inf = Sph_Inf ?  (Math.sign(Sph_Inf) === 1 || Math.sign(Sph_Inf) === 0 ? `+${Sph_Inf}` : Sph_Inf) : null
    },
    FixSphSupValue (sph) {
      //convert string to float and fix
      const Sph_Sup = sph ? parseFloat(sph).toFixed(2) : null

      this.Sph_Sup = Sph_Sup ? (Math.sign(Sph_Sup) === 1 || Math.sign(Sph_Sup) === 0 ? `+${Sph_Sup}` : Sph_Sup) : null
    },
    FixCylInfValue (cyl) {
      //convert string to float and fix
      const Cyl_Inf = cyl ?  parseFloat(cyl).toFixed(2) : null

      this.Cyl_Inf = Cyl_Inf ? (Math.sign(Cyl_Inf) === 1 || Math.sign(Cyl_Inf) === 0 ? `+${Cyl_Inf}` : Cyl_Inf) : null
    },
    FixCylSupValue (cyl) {
      //convert string to float and fix
      const Cyl_Sup = cyl ? parseFloat(cyl).toFixed(2) : null

      this.Cyl_Sup = Cyl_Sup ?  (Math.sign(Cyl_Sup) === 1 || Math.sign(Cyl_Sup) === 0 ? `+${Cyl_Sup}` : Cyl_Sup) : null
    },
    FixSphInfValueUpdate (sph) {
      //convert string to float and fix
      const Sph_Inf = sph ? parseFloat(sph).toFixed(2) : null

      return Sph_Inf ?  (Math.sign(Sph_Inf) === 1 || Math.sign(Sph_Inf) === 0 ? `+${Sph_Inf}` : Sph_Inf) : null
    },
    FixSphSupValueUpdate (sph) {
      //convert string to float and fix
      const Sph_Sup = sph ? parseFloat(sph).toFixed(2) : null

      return  Sph_Sup ? (Math.sign(Sph_Sup) === 1 || Math.sign(Sph_Sup) === 0 ? `+${Sph_Sup}` : Sph_Sup) : null
    },
    FixCylInfValueUpdate (cyl) {
      //convert string to float and fix
      const Cyl_Inf = cyl ?  parseFloat(cyl).toFixed(2) : null

      return  Cyl_Inf ? (Math.sign(Cyl_Inf) === 1 || Math.sign(Cyl_Inf) === 0 ? `+${Cyl_Inf}` : Cyl_Inf) : null
    },
    FixCylSupValueUpdate (cyl) {
      //convert string to float and fix
      const Cyl_Sup = cyl ? parseFloat(cyl).toFixed(2) : null

      return Cyl_Sup ?  (Math.sign(Cyl_Sup) === 1 || Math.sign(Cyl_Sup) === 0 ? `+${Cyl_Sup}` : Cyl_Sup) : null
    },
    showGammeVerre () {
      this.popupGammeVerre = true
    },
    showModeleVerre () {
      this.popupReferenceVerre = true
    },
    showTypeVerre () {
      // this.getTypeDeVerres()
      this.popupFoyerVerre = true
    },
    showMatiereVerre () {
      this.getMatieresVerre()
      this.popupMatiereVerre = true
    },
    showIndiceVerre () {
      this.getIndiceVerre()
      this.popupIndiceVerre = true
    },
    showVersionVerre () {
      this.popupVersionVerre = true
    },
    showGammeName (id) {
      return this.$store.getters['verre/showGammeName'](id)
    },
    showIndiceName (id) {
      return this.$store.getters['verre/showIndiceName'](id)
    },
    showMatiereName (id) {
      return this.$store.getters['verre/showMatiereName'](id)
    },
    showVersionName (id) {
      this.getVersionVerre()
      if (this.IdModelVer !== null) {
        const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id)

        if (modeleVerre.IdVersion) this.show_version_name = this.$store.getters['verre/showVersionName'](modeleVerre.IdVersion)
        if (modeleVerre.IdGam) this.show_gamme_verre_name = this.$store.getters['verre/showGammeName'](modeleVerre.IdGam)
        if (modeleVerre.IdMatiere) this.show_matiere_verre_name = this.$store.getters['verre/showMatiereName'](modeleVerre.IdMatiere)
        if (modeleVerre.IdFoyer) this.show_foyer_verre_name = this.$store.getters['verre/showFoyerName'](modeleVerre.IdFoyer)
        if (modeleVerre.IdIndice) this.show_indice_verre_name = this.$store.getters['verre/showIndiceName'](modeleVerre.IdIndice)

        this.getMatricePrix()
      } else {
        return ''
      }
    },
    getFournisseurVerreById (id) {
      return this.$store.getters['verre/getFournisseurVerreById'](id)
    },
    showFoyerName (id) {
      return this.$store.getters['verre/showFoyerName'](id)
    },
    calculPrixVenteOption (idGam, idIndice, majoration) {
      const payload = {
        gamme: idGam,
        indice: idIndice
      }
      const version = this.$store.getters['verre/getOptionByGammeIdAndBase'](payload)
      return (version ? version.Prix_vente_version : 0) + (majoration ? majoration : 0)
    },
    showCatalogueInfo (IdFourVerr) {
      this.current_Fabrications = this.$store.getters['verre/filterFabricationByIdFour'](IdFourVerr)
      this.current_Traitements = this.$store.getters['verre/filterTraitementByIdFour'](IdFourVerr)
      this.current_Colorations = this.$store.getters['verre/filterColorationByIdFourn'](IdFourVerr)
      //this.current_Plages = this.$store.getters['verre/filterTraitementByIdFour'](IdFourVerr)
    },
    showOptionInfo (IdGammeVerreFilter) {
      this.current_Options = this.$store.getters['verre/filterOptionByIdGamme'](IdGammeVerreFilter)
    },
    groupByProperty (collection, property) {
      let i = 0, val = null, index = null
      const  values = [], result = []
      for (; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        if (index > -1) result[index].push(collection[i])
        else {
          values.push(val)
          result.push([collection[i]])
        }
      }
      return result
    },
    getGammeMatricePrice (collection, indice) {
      const infos = collection.find((o) => {
        if (o.IdIndice === indice) return true
      })
      return infos
    },

    AddTraitement () {
      const payload = {
        IdFour: this.IdFourVerr,
        IdMatiere: this.Id_Matiere,
        Libelle_Traitement: this.Libelle_Traitement,
        prix_achat: this.Prix_achat_Traitement,
        prix_Traitement: this.prix_Traitement
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddTraitement', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Libelle_Traitement = ''
          this.prix_Traitement = 0

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    updateTraitement (Libelle_Trait, pa, prix_Trait, idTraitement, Id_Four, Idmatiere) {
      const payload = {
        IdFour: Id_Four,
        IdMatiere: Idmatiere,
        Libelle_Traitement: Libelle_Trait,
        prix_achat: pa,
        prix_Traitement: prix_Trait,
        id: idTraitement
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateTraitement', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteTraitement (data) {
      this.IdTraitement = data._id
      this.popupTraitement = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le traitement  "${data.Libelle_Traitement}`,
        accept: this.removeTraitement,
        acceptText: 'Supprimer'
      })
    },
    removeTraitement () {
      this.popupTraitement = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeTraitement', this.IdTraitement)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Traitement supprimé',
            text: 'Le Traitement selectionné a bien été supprimée'
          })
          this.IdTraitement = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    AddGammeDeVerre () {
      const payload = {
        IdFourVerr: this.IdFourVerr,
        IdFoyer: this.IdFoyer,
        Libelle_GamVer: this.Libelle_GamVer
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddGammeDeVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Libelle_GamVer = ''
          this.IdFoyer = null
          this.getGammeVerres()
          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    UpdateGammeVerre (LibelleGamVer, idFoyer, idGamVer, idFourn) {
      const payload = {
        IdFourVerr: idFourn,
        IdFoyer: idFoyer,
        Libelle_GamVer: LibelleGamVer,
        id: idGamVer
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateGammeVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    getGammeVerres () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getGammeVerres')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteGammeVerre (data) {
      this.IdGammeVerre = data._id
      this.popupGammeVerre = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la gamme de verre  "${data.Libelle_GamVer}`,
        accept: this.DeleteGammeVerre,
        acceptText: 'Supprimer'
      })
    },
    DeleteGammeVerre () {
      this.popupGammeVerre = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeGammeVerre', this.IdGammeVerre)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Gamme de  verre supprimé',
            text: 'La Gamme de verre selectionné a bien été supprimée'
          })
          this.IdFoyer = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    AddTypeDeVerre () {
      const payload = {
        Libelle_Foyer: this.Libelle_Foyer
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddTypeDeVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Libelle_Foyer = ''
          /* this.getTypeDeVerres() */
          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    UpdateTypeVerre (typeVerre, idVerre) {
      const payload = {
        Libelle_Foyer: typeVerre,
        id: idVerre
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateTypeDeVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteTypeVerre (data) {
      this.IdFoyer = data._id
      this.popupFoyerVerre = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le type de verre  "${data.Libelle_Foyer}`,
        accept: this.DeleteTypeVerre,
        acceptText: 'Supprimer'
      })
    },
    DeleteTypeVerre () {
      this.popupFoyerVerre = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeTypeDeVerre', this.IdFoyer)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Type de  verre supprimé',
            text: 'Le Type de verre selectionné a bien été supprimée'
          })
          this.IdFoyer = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getTypeDeVerres () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getTypeDeVerres')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    AddColoration () {
      const payload = {
        IdFour: this.IdFourVerr,
        Libelle_Coloration: this.Libelle_Coloration,
        Prix_achat_Coloration: this.Prix_achat_Coloration,
        Prix_vente_Coloration: this.Prix_vente_Coloration
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddColoration', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    updateColoration (LibelleColoration, pa, pv, idColoration, Id_Four) {
      const payload = {
        IdFour: Id_Four,
        Libelle_Coloration: LibelleColoration,
        Prix_achat_Coloration: pa,
        Prix_vente_Coloration: pv,
        id: idColoration
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateColoration', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteColoration (data) {
      this.IdColoration = data._id
      this.popupColoration = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la coloration  "${data.Libelle_Coloration}`,
        accept: this.removeColoration,
        acceptText: 'Supprimer'
      })
    },
    removeColoration () {
      this.popupColoration = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeColoration', this.IdColoration)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Traitement supprimé',
            text: 'La Coloration selectionnée a bien été supprimée'
          })
          this.IdColoration = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getColorations () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getColorations')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    AddFabrication () {
      const payload = {
        IdFour: this.IdFourVerr,
        Libelle_Fabrication: this.Libelle_Fabrication,
        Prix_achat_Fabrication: this.Prix_achat_Fabrication,
        Prix_vente_Fabrication: this.Prix_vente_Fabrication
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddFabrication', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    updateFabrication (LibelleFabrication, pa, pv, idFabrication, Id_Four) {
      const payload = {
        IdFour: Id_Four,
        Libelle_Fabrication: LibelleFabrication,
        Prix_achat_Fabrication: pa,
        Prix_vente_Fabrication: pv,
        id: idFabrication
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateFabrication', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteFabrication (data) {
      this.IdFabrication = data._id
      this.popupFabrication = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la fabrication  "${data.Libelle_Fabrication}`,
        accept: this.removeFabrication,
        acceptText: 'Supprimer'
      })
    },
    removeFabrication () {
      this.popupFabrication = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeFabrication', this.IdFabrication)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Traitement supprimé',
            text: 'La Fabrication selectionnée a bien été supprimée'
          })
          this.IdFabrication = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getFabrications () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getFabrications')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    AddTeinte () {
      const payload = {
        IdFour: this.IdFourVerr,
        Libelle_Teinte: this.Libelle_Teinte,
        Prix_achat_Teinte: this.Prix_achat_Teinte,
        Prix_vente_Teinte: this.Prix_vente_Teinte
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddTeinte', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    updateTeinte (LibelleTeinte, pa, pv, idTeinte, Id_Four) {
      const payload = {
        IdFour: Id_Four,
        Libelle_Teinte: LibelleTeinte,
        Prix_achat_Teinte: pa,
        Prix_vente_Teinte: pv,
        id: idTeinte
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateTeinte', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteTeinte (data) {
      this.IdTeinte = data._id
      this.popupTeinte = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la teinte  "${data.Libelle_Teinte}`,
        accept: this.removeTeinte,
        acceptText: 'Supprimer'
      })
    },
    removeTeinte () {
      this.popupTeinte = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeTeinte', this.IdTeinte)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Traitement supprimé',
            text: 'La Teinte selectionnée a bien été supprimée'
          })
          this.IdFabrication = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getTeinte () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getTeinte')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    AddMatiereVerre () {
      const payload = {
        Libelle_Matiere: this.Libelle_Matiere
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddMatiereVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Libelle_Matiere = ''
          /* this.getTypeDeVerres() */
          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    UpdateMatiereVerre (libelleMatiere, idMatiere) {
      const payload = {
        Libelle_Matiere: libelleMatiere,
        id: idMatiere
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateMatiereVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteMatiereVerre (data) {
      this.IdMatiere = data._id
      this.popupMatiereVerre = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la matière  "${data.Libelle_Matiere}`,
        accept: this.DeleteMatiereVerre,
        acceptText: 'Supprimer'
      })
    },
    DeleteMatiereVerre () {
      this.popupMatiereVerre = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeMatiereVerre', this.IdMatiere)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Matière supprimer',
            text: 'La Matière selectionnée a été supprimée'
          })
          this.IdMatiere = null
          this.getMatieresVerre()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getMatieresVerre () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getMatieresVerre')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    AddIndiceVerre () {
      const payload = {
        indice: this.indice
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddIndiceVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.indice = ''
          /* this.getTypeDeVerres() */
          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })

    },
    updateIndiceVerre (IndiceVerre, idIndice) {
      const payload = {
        indice: IndiceVerre,
        id: idIndice
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateIndiceVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()

        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteIndiceVerre (data) {
      this.IdIndice = data._id
      this.popupIndiceVerre = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer l indice  ${data.indice}`,
        accept: this.DeleteIndiceVerre,
        acceptText: 'Supprimer'
      })
    },
    DeleteIndiceVerre () {
      this.popupIndiceVerre = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeIndiceVerre', this.IdIndice)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Indice supprimer',
            text: 'L\' Indice selectionnée a été supprimée'
          })
          this.IdIndice = null
          this.getIndiceVerre()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getIndiceVerre () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getIndiceVerre')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    AddVersionVerre () {
      const payload = {
        Version: this.Version,
        IdFour: this.IdFourVerr,
        IdGam: this.IdGam,
        IdIndice: this.Id_Indice,
        Libelle_indice_Four: this.Libelle_indice_Four,
        Libelle_version: this.Libelle_version,
        Prix_achat_version: this.Prix_achat_version,
        Prix_vente_version: this.Prix_vente_version,
        Majore_Prix_Base: this.Majore_Prix_Base,
        Prix_Base: this.Prix_Base,
        Sph_Inf: this.Sph_Inf,
        Sph_Sup: this.Sph_Sup,
        Cyl_Inf: this.Cyl_Inf,
        Cyl_Sup: this.Cyl_Sup
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/AddVersionVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.Version = ''
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    updateVersionVerre (Id_Four, Id_Gam, Id_Indice, Libelle_Indice, Libelle_Version, pa, pv, mjb, pb, sphI, sphS, cylI, cylS, IdVersion) {
      const payload = {
        IdFour: Id_Four,
        IdGam: Id_Gam,
        IdIndice: Id_Indice,
        Libelle_indice_Four: Libelle_Indice,
        Libelle_version: Libelle_Version,
        Prix_achat_version: pa,
        Prix_vente_version: pv,
        Majore_Prix_Base: mjb,
        Prix_Base: pb,
        Sph_Inf: sphI,
        Sph_Sup: sphS,
        Cyl_Inf: cylI,
        Cyl_Sup: cylS,
        id: IdVersion
      }

      console.log(payload)

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('verre/updateVersionVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    confirmDeleteVersionVerre (data) {
      this.IdVersion = data._id
      this.popupVersionVerre = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la version  ${data.Version}`,
        accept: this.DeleteVersionVerre,
        acceptText: 'Supprimer'
      })
    },
    DeleteVersionVerre () {
      this.popupVersionVerre = true
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/removeVersionVerre', this.IdVersion)
        .then(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Version supprimer',
            text: 'La version selectionnée a été supprimée'
          })
          this.IdVersion = null
          this.getVersionVerre()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    getVersionVerre () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('verre/getVersionVerre')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },

    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    },

    getTraitement () {
      this.$store.dispatch('verre/getTraitement')
        .catch((error) => { console.log(error) })
    }
  },
  created () {
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    if (!(this.$store.state.verre.gammes_verres.length > 0)) this.getGammeVerres()
    if (!(this.$store.state.verre.versions.length > 0)) this.getVersionVerre()
    if (!(this.$store.state.verre.types_verre.length > 0)) this.getTypeDeVerres()
    if (!(this.$store.state.verre.matieres_verres.length > 0)) this.getMatieresVerre()
    if (!(this.$store.state.verre.indices.length > 0)) this.getIndiceVerre()
    if (!(this.$store.state.verre.traitements.length > 0)) this.getTraitement()
    if (!(this.$store.state.verre.colorations.length > 0)) this.getColorations()
    if (!(this.$store.state.verre.fabrications.length > 0)) this.getFabrications()
    if (!(this.$store.state.verre.teintes.length > 0)) this.getTeinte()
  }
}
</script>
<style scoped>
.btnTextColor {
  color: black;
}

.Table {
  border:   1px solid black;
}
.thTable, .tdTable {
  border: 1px solid black;
  padding: 3px;
}
</style>
